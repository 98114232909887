import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerEmployeeListByCustomer } from '../api/useCustomerEmployeeList';
import { CustomerEmployeeListComponent } from './customerEmployeeList.component';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId: string;
};

export const CustomerEmployeeListContainer = ({ customerId }: Props) => {
    const { i18n } = useTranslation();

    const lang = i18n.language;

    const {
        data: customerEmployeeListData,
        isLoading: isCustomerEmployeeListLoading,
        error: customerEmployeeListError,
        isError: isCustomerEmployeeListError
    } = useCustomerEmployeeListByCustomer(customerId);

    if (isCustomerEmployeeListLoading) {
        return <Loading />;
    }

    if (isCustomerEmployeeListError) {
        console.error(customerEmployeeListError);
        return <></>;
    }

    customerEmployeeListData.forEach((item) => (item.is_active = Boolean(Number(item.is_active))));

    customerEmployeeListData.forEach((item) => {
        item.from_date = item.from_date ? new Date(item.from_date).toLocaleDateString(lang) : '';
        item.to_date = item.to_date ? new Date(item.to_date).toLocaleDateString(lang) : '';
    });

    return (
        <CustomerEmployeeListComponent data={customerEmployeeListData} />
    );
};
