import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ICustomerEmployee } from '../interfaces/customerEmployee';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

const useStyles = makeStyles((theme) => ({
    iconOpen: {
        color: theme.palette.success.dark
    },
    iconClosed: {
        color: theme.palette.error.main
    }
}));

type Props = {
    data: ICustomerEmployee[];
};

export const CustomerEmployeeListComponent = ({ data }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'customerEmployeeList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.EMPLOYEES}/${params.row.id}`);
    };

    const columns: GridColDef[] = [
        {
            field: 'person_prefix',
            headerName: t(`EMPLOYEES.FIELDS.person_prefix`),
            width: getColumnsWidth('person_prefix', 100, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('person_prefix', true, columnVisibility)
        },
        {
            field: 'firstname',
            headerName: t(`EMPLOYEES.FIELDS.firstname`),
            width: getColumnsWidth('firstname', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('firstname', true, columnVisibility)
        },
        {
            field: 'lastname',
            headerName: t(`EMPLOYEES.FIELDS.lastname`),
            width: getColumnsWidth('lastname', 200, columnWidth),
            hide: !isColumnsVisibile('lastname', true, columnVisibility)
        },
        {
            field: 'customer',
            headerName: t(`EMPLOYEES.FIELDS.customer`),
            width: getColumnsWidth('customer', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('customer', true, columnVisibility)
        },
        {
            field: 'unit',
            headerName: t(`EMPLOYEES.FIELDS.unit`),
            width: getColumnsWidth('unit', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('unit', true, columnVisibility)
        },
        {
            field: 'job_title',
            headerName: t(`EMPLOYEES.FIELDS.job_title`),
            width: getColumnsWidth('job_title', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('job_title', true, columnVisibility)
        },
        {
            field: 'phone_default',
            headerName: t(`EMPLOYEES.FIELDS.phone_default`),
            width: getColumnsWidth('phone_default', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('phone_default', true, columnVisibility)
        },
        {
            field: 'mobile_default',
            headerName: t(`EMPLOYEES.FIELDS.company_mobile`),
            width: getColumnsWidth('mobile_default', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('mobile_default', true, columnVisibility)
        },
        {
            field: 'email_default',
            headerName: t(`EMPLOYEES.FIELDS.email_default`),
            width: getColumnsWidth('email_default', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('email_default', true, columnVisibility)
        },
        {
            field: 'company_annotation',
            headerName: t(`EMPLOYEES.FIELDS.company_annotation`),
            width: getColumnsWidth('company_annotation', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('company_annotation', true, columnVisibility)
        },
        {
            field: 'is_active',
            headerName: t(`EMPLOYEES.FIELDS.is_active`),
            width: getColumnsWidth('is_active', 200, columnWidth),
            hide: !isColumnsVisibile('is_active', false, columnVisibility),
            sortable: false,
            filterable: false,
            type: 'singleSelect',
            valueOptions: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sì' }
            ],
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                if (params.row.is_active || parseInt(params.row.is_active) === 1) {
                    return (
                        <span>
                            <LockOutlinedIcon className={classes.iconClosed} />
                        </span>
                    );
                }
                return (
                    <span className={classes.iconOpen}>
                        <LockOpenOutlined />
                    </span>
                );
            },
            disableColumnMenu: true
        },
        {
            field: 'id',
            headerName: t('EMPLOYEES.FIELDS.id'),
            type: 'number',
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            sortable: false,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        }
    ];

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    height="60vh"
                    handleRowDoubleClick={handleRowDoubleClick}
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
