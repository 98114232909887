import React from 'react';
import {
    useSalesListByCustomerPastYears,
    useSalesListByCustomerYear,
    useTotYearByCustomer,
    useYearList
} from '../../adesSales/api/useSalesList';
import { AdesSalesFullListComponent } from './customerAdesSalesList.component';
import { Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Pivot } from '../../../components/pivot/pivot.component';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId: string;
};

export const CustomerAdesSalesListContainer = ({ customerId }: Props) => {
    const [year, setYear] = React.useState(new Date().getFullYear());

    const { t } = useTranslation();

    /** Used in the year selector and in the grid for the past years columns */
    const {
        data: yearListData,
        isLoading: yearListIsLoading,
        error: yearListerror,
        isError: yearListIsError
    } = useYearList(customerId);

    const { data, isLoading: isDataLoading, error, isError } = useSalesListByCustomerYear(customerId, year);

    /** Used in the pivot, on the top right of the page, over the data grid */
    const {
        data: totYearBycustomerData,
        isLoading: isTotYearBycustomerLoading,
        error: totYearBycustomerError,
        isError: isTotYearBycustomerError
    } = useTotYearByCustomer(customerId);

    /** Used for calculating past qty and selling and pass them into data (useSalesListByCustomerYear)  */
    const {
        data: adesQtyPastYearListData,
        isLoading: isAdesQtyPastYearListLoading,
        error: adesQtyPastYearListError,
        isError: isAdesQtyPastYearListError
    } = useSalesListByCustomerPastYears(customerId, year);

    React.useEffect(() => {
        if (yearListData?.length) {
            setYear(yearListData[yearListData.length - 1].year_ref);
        }
    }, [yearListData]);

    if (isDataLoading || yearListIsLoading || isTotYearBycustomerLoading || isAdesQtyPastYearListLoading) {
        return <Loading />;
    }

    if (isError) {
        console.error(error);
        return <></>;
    }
    if (yearListIsError) {
        console.error(yearListerror);
        return <></>;
    }
    if (isTotYearBycustomerError) {
        console.error(totYearBycustomerError);
        return <></>;
    }
    if (isAdesQtyPastYearListError) {
        console.error(adesQtyPastYearListError);
        return <></>;
    }

    const yearList = [];

    yearListData.forEach((item) => {
        if (!yearList.includes(item.year_ref)) {
            yearList.push(Number(item.year_ref));
        }
    });

    yearList.sort();

    console.log('A:', data[0]);
    console.log('B:', adesQtyPastYearListData);

    data.forEach((item) => {
        /** tot row */
        item['tot_sell'] = (Number(item.quantity) * Number(item.sell_price)).toFixed(5);

        /** qty past years */
        const qtyPastYearList = adesQtyPastYearListData.filter(
            (qtyPastYear) => qtyPastYear.product_id === item.product_id && qtyPastYear.customer_id === item.customer_id
        );

        if (qtyPastYearList?.length) {
            qtyPastYearList.forEach((qtyPastYear) => {
                item[`qty_${qtyPastYear.year_ref}`] = qtyPastYear.tot_quantity;
                item[`tot_${qtyPastYear.year_ref}`] = (qtyPastYear.tot_quantity * qtyPastYear.sell_price).toFixed(5);
            });
        }
    });

    return (
        <>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
                <Box
                    display="flex"
                    alignItems="center"
                    bgcolor={'#fff'}
                    px={2}
                    borderRadius="4px   "
                    boxShadow={
                        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }>
                    <Select value={year} variant="outlined" onChange={(e) => setYear(e.target.value as number)}>
                        {yearList.map((item) => (
                            <MenuItem key={item} value={item}>
                                <Box component="span" paddingX={1}>
                                    {item}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                    <Box pl={2}>
                        <InputLabel>{t('ADES_SALES.FIELDS.year_ref')}</InputLabel>
                    </Box>
                </Box>
                <Box>
                    <Pivot
                        data={totYearBycustomerData}
                        headerKey={'year_ref'}
                        rowKey={'mandator'}
                        sumKey={'tot_customer_year'}
                        isCurrency
                    />
                </Box>
            </Box>
            <AdesSalesFullListComponent data={data} yearList={yearList} yearSelected={year} showCustomer={false} />
        </>
    );
};
